/* START UK-MARGIN */
@media (min-width: 640px) {
  .uk-margin-remove-left\@s {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@s {
    margin-right: 0 !important;
  }

  .uk-margin-small-top\@s {
    margin-top: 10px !important;
  }

  .uk-margin-small-bottom\@s {
    margin-bottom: 10px !important;
  }

  .uk-margin-medium-top\@s {
    margin-top: 40px !important;
  }

  .uk-margin-medium-bottom\@s {
    margin-bottom: 40px !important;
  }

  .uk-margin-large-top\@s {
    margin-top: 70px !important;
  }

  .uk-margin-large-bottom\@s {
    margin-bottom: 70px !important;
  }

  .uk-margin-large-left\@s {
    margin-left: 70px !important;
  }

  .uk-margin-large-right\@s {
    margin-right: 70px !important;
  }
}

@media (min-width: 960px) {
  .uk-margin-remove-left\@m {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@m {
    margin-right: 0 !important;
  }

  .uk-margin-small-top\@m {
    margin-top: 10px !important;
  }

  .uk-margin-small-bottom\@m {
    margin-bottom: 10px !important;
  }

  .uk-margin-medium-top\@m {
    margin-top: 40px !important;
  }

  .uk-margin-medium-bottom\@m {
    margin-bottom: 40px !important;
  }

  .uk-margin-large-top\@m {
    margin-top: 70px !important;
  }

  .uk-margin-large-bottom\@m {
    margin-bottom: 70px !important;
  }

  .uk-margin-medium-left\@m {
    margin-left: 40px !important;
  }

  .uk-margin-medium-right\@m {
    margin-right: 40px !important;
  }

  .uk-margin-large-left\@m {
    margin-left: 70px !important;
  }

  .uk-margin-large-right\@m {
    margin-right: 70px !important;
  }
}

@media (min-width: 1200px) {
  .uk-margin-remove-left\@l {
    margin-left: 0 !important;
  }

  .uk-margin-remove-right\@l {
    margin-right: 0 !important;
  }

  .uk-margin-small-top\@l {
    margin-top: 10px !important;
  }

  .uk-margin-small-bottom\@l {
    margin-bottom: 10px !important;
  }

  .uk-margin-medium-top\@l {
    margin-top: 40px !important;
  }

  .uk-margin-medium-bottom\@l {
    margin-bottom: 40px !important;
  }

  .uk-margin-large-top\@l {
    margin-top: 70px !important;
  }

  .uk-margin-large-bottom\@l {
    margin-bottom: 70px !important;
  }
}

/* END UK-MARGIN */

/* START UK-PADDING */
@media (min-width: 640px) {
  .uk-padding-remove-left\@s {
    padding-left: 0 !important;
  }

  .uk-padding-remove-right\@s {
    padding-right: 0 !important;
  }

  .uk-padding-small-top\@s {
    padding-top: 10px !important;
  }

  .uk-padding-small-bottom\@s {
    padding-bottom: 10px !important;
  }

  .uk-padding-medium-top\@s {
    padding-top: 40px !important;
  }

  .uk-padding-medium-bottom\@s {
    padding-bottom: 40px !important;
  }

  .uk-padding-large-top\@s {
    padding-top: 70px !important;
  }

  .uk-padding-large-bottom\@s {
    padding-bottom: 70px !important;
  }
}

@media (min-width: 960px) {
  .uk-padding-remove-left\@m {
    padding-left: 0 !important;
  }

  .uk-padding-remove-right\@m {
    padding-right: 0 !important;
  }

  .uk-padding-small-top\@m {
    padding-top: 10px !important;
  }

  .uk-padding-small-bottom\@m {
    padding-bottom: 10px !important;
  }

  .uk-padding-medium-top\@m {
    padding-top: 40px !important;
  }

  .uk-padding-medium-bottom\@m {
    padding-bottom: 40px !important;
  }

  .uk-padding-large-top\@m {
    padding-top: 70px !important;
  }

  .uk-padding-large-bottom\@m {
    padding-bottom: 70px !important;
  }
}

@media (min-width: 1200px) {
  .uk-padding-remove-left\@l {
    padding-left: 0 !important;
  }

  .uk-padding-remove-right\@l {
    padding-right: 0 !important;
  }

  .uk-padding-small-top\@l {
    padding-top: 10px !important;
  }

  .uk-padding-small-bottom\@l {
    padding-bottom: 10px !important;
  }

  .uk-padding-medium-top\@l {
    padding-top: 40px !important;
  }

  .uk-padding-medium-bottom\@l {
    padding-bottom: 40px !important;
  }

  .uk-padding-large-top\@l {
    padding-top: 70px !important;
  }

  .uk-padding-large-bottom\@l {
    padding-bottom: 70px !important;
  }
}

/* END UK-PADDING */

.nav {
  position: absolute;
  top: 15px;
  z-index: 99;
  left: 0;
  right: 0;
}

.uk-sticky-placeholder {
  height: 0 !important;
}

.top-wrap::before,
.overlay-wrap::before {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  content: '';
  z-index: 1;
}

.big-message-dark {
  font-size: 3em;
  font-weight: 100;
  color: #fff;
}

.uk-subnav-pill > * > :first-child {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.04);
}

.top-container {
  z-index: 2;
}

.uk-logo img {
  height: 38px;
}

.subtitle-text {
  font-size: 1.2em;
  font-weight: 400;
}

.logos-grid img {
  display: block;
  width: 120px;
  margin: 0 auto;
  filter: grayscale(100%);
  transition: filter 0.25s;
}

.logos-grid img:hover {
  display: block;
  width: 120px;
  margin: 0 auto;
  filter: grayscale(0);
}

.section-title-primary {
  font-weight: 700 !important;
  color: #3d9dd6 !important;
}

.uk-section-primary,
.uk-background-primary {
  background-color: #3d9dd6 !important;
}

.uk-section-success,
.uk-background-success {
  background-color: #53ac86 !important;
}

.uk-section-warning,
.uk-background-warning {
  background-color: #fbb232 !important;
}

.uk-section-secondary,
.uk-background-secondary {
  background-color: #00243e !important;
}
