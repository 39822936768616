.solution-wrap::before,
.overlay-wrap::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  content: '';
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.top-wrap-height {
  height: 80vh;
  min-height: 460px;
  transition: height 0.25s;
}

.top-container {
  z-index: 2;
}
