.partners-section p {
  text-align: center;
}

.partners-section > img {
  object-fit: contain;
  object-position: 50% 50%;
}

@media (min-width: 640px) {
  .partners-section p {
    text-align: left;
  }
}
